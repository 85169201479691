<template>
  <div>
    <section class="authorize_body" v-show="showPage">
      <el-card class="authorize_card">
        <h4>Authorize</h4>
        <div class="d-flex justify-content-center flex-column align-items-center mt-5">
          <p>Confirm authorization to Masterclasses?</p>
          <div class="btn">
            <el-button type="primary" @click="funConfirm(1)">Confirm</el-button>
            <el-button type="info" @click="funCancel">Cancel</el-button>
          </div>
          <!-- <iframe id="myIframe" src="http://192.168.199.47:8081/#/" style="display: none" /> -->
        </div>
      </el-card>
    </section>
    <section v-if="!showPage" v-loading="loading" style="height: 100vh;"></section>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        showPage: false,
        loading: true
      }
    },

    async created() {
      let secret = this.$route.query.secret
      this.redirect_url = this.$route.query.redirect_url
      let res = await this.$http.jciAuthorize({ secret })
      if (res.status == 10088) {
        this.userInfo = res.data
        this.showPage = true
      } else if (res.status == 10087) {
        this.funConfirm(0, res.data)
      } else if (res.status == 10086) {
        window.open(`${this.redirect_url}#/?type=0`, '_self')
      }
    },

    methods: {
      funCancel() {
        window.open(`${this.redirect_url}#/`, '_self')
      },

      async funConfirm(flag, obj) {
        let data = obj || this.userInfo
        let res = await this.$http.oauthToken(data)
        if (res.status == 50018) return
        if (flag == 1) {
          let secret = this.$route.query.secret
          let newRes = await this.$http.jciAuthorizeRecord({ secret })
          if (newRes.status) window.open(`${this.redirect_url}#/?type=1&token=${res.data.token}`, '_self')
        } else {
          window.open(`${this.redirect_url}#/?type=1&token=${res.data.token}`, '_self')
        }
      },

      // toAuthorize(data) {
      //   return new Promise(resolve => {
      //     var myIframe = document.getElementById("myIframe");
      //     if (myIframe) {
      //       myIframe.contentWindow.postMessage(JSON.stringify(data), 'http://192.168.199.47:8081/#/');
      //       resolve()
      //     }
      //   });
      // },
    }
  }
</script>
<style lang="less" scoped>
  .authorize_body {
    width: 100%;
    height: 100vh;
    padding-top: 100px;

    ::v-deep .el-card__body {
      padding: 0 !important;
    }
  }

  .authorize_card {
    width: 800px;
    height: 460px;
    margin: auto;

    h4 {
      line-height: 70px;
      border-bottom: 1px solid #ccc;
      padding-left: 20px;
      font-size: 18px;
    }

    p {
      font-size: 32px;
      font-weight: 500;
    }

    .btn {
      margin-top: 120px;

      button {
        width: 228px;
        height: 52px;
        font-size: 18px
      }

      .el-button+.el-button,
      .el-checkbox.is-bordered+.el-checkbox.is-bordered {
        margin-left: 20px;
        background-color: #ccc;
        border-color: #ccc;
      }
    }
  }
</style>